/* eslint-disable */
import { Router as SourceRouter } from 'SourceComponent/Router/Router.component';
import {
    BEFORE_ITEMS_TYPE
} from 'Component/Router/Router.config';
import NavigationTabs from 'Component/NavigationTabs';
export * from 'SourceComponent/Router/Router.component';

export class Router extends SourceRouter {
    [BEFORE_ITEMS_TYPE] = [
        ...this.BEFORE_ITEMS_TYPE.filter(({ component: { type } }) => type !== NavigationTabs)
    ]
}

export default Router;