const _getAvailableVisualOptions = (args, callback, instance) => {
    const { product: { configurable_options = {} } } = instance.props;
    if (Object.keys(configurable_options).length === 0) {
        return [];
    }
    const { attribute_options } = Object.values(configurable_options)[0];
    return attribute_options ? Object.values(attribute_options).reduce(
        (acc, option) => {
            const {
                swatch_data,
                label
            } = option;
            const { type, value } = swatch_data || {};
            if (type && value) {
                acc.push({ value, label, type });
            }

            return acc;
        },
        []
    ) : [];
};

export default {
    'Component/ProductCard/Container': {
        'member-function': {
            _getAvailableVisualOptions
        }
    }
};
