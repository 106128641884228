import Event, { EVENT_GTM_VIEW_ITEM_LIST } from "../util/Event";
import { getListId } from "../util/EventConfig";

const componentDidUpdate = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    const [prevProps] = args;

    const {
        pages,
        isLoading,
        isPageLoading
    } = instance.props;
    const {
        isLoading: prevIsLoading,
        isPageLoading: prevIsPageLoading,
        pages: prevPages
    } = prevProps;
    const currentPage = instance._getPageFromUrl();

   if (!Object.keys(pages || {}).length
        || !Object.keys(pages[currentPage] || {}).length
        || isLoading
        || isPageLoading
        || (isLoading === prevIsLoading && isPageLoading === prevIsPageLoading)
    ) {
        return result;
    }

    const pagesKeys = Object.keys(pages);
    const prevPagesKeys = Object.keys(prevPages);
    const [ page = currentPage ] = pagesKeys.filter(key => !prevPagesKeys.includes(key));

    const items = pages[page];
    const listId = getListId();

    Event.dispatch(EVENT_GTM_VIEW_ITEM_LIST, {
        items,
        listId
    });

    return result;
};

export default {
    'Component/ProductList/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};