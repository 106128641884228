/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_BEGIN_CHECKOUT } from '../../../util/Event';
import ProductHelper from '../utils/Product4';
import BaseEvent from './BaseEvent.event';

export const CHECKOUT_EVENT_DELAY = 500;
export const SPAM_PROTECTION_DELAY = 1000;

/**
 * On checkout
 */
class BeginCheckout4Event extends BaseEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = CHECKOUT_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_GTM_BEGIN_CHECKOUT, ({ totals }) => {
            this.handle(totals);
        });
    }

    /**
     * Handle
     */
    handler(totals) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const {
            items: cartItems = []
        } = totals;

        const items = cartItems.map(({ qty, ...cartItem}, idx) => ({
            ...ProductHelper.getItemData(cartItem),
            quantity: qty,
            index: idx
        }));

        const value = ProductHelper.getItemsValue(items);

        this.clearEcommerce();

        this.pushEventData({
            ecommerce: {
                currency: this.getCurrencyCode(),
                value,
                items
            }
        });
    }
}

export default BeginCheckout4Event;
