import Event, {
    EVENT_GTM_SELECT_ITEM
} from '../util/Event';
import { getListId } from '../util/EventConfig';

const renderCardLinkWrapper = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    if (!result || !React.isValidElement(result)) {
        return result;
    }

    const {
        product,
        currentVariantIndex: configurableVariantIndex
    } = instance.props;

    const onClick = () => {
        const {
            onClick: originalOnClick
        } = result.props;

        Event.dispatch(EVENT_GTM_SELECT_ITEM, {
            product: {
                ...product,
                configurableVariantIndex
            },
            listId: getListId()
        });

        if (originalOnClick) {
            originalOnClick();
        }
    };

    const updatedResult = React.cloneElement(result, { onClick });

    return updatedResult;
};

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            renderCardLinkWrapper
        }
    }
};
