/* eslint-disable */
import { StoreSwitcher as SourceStoreSwitcher } from 'HoeksComponent/StoreSwitcher/StoreSwitcher.component';

// import new extended style file
import './StoreSwitcher.override.style.scss';

export class StoreSwitcher extends SourceStoreSwitcher {

    renderDesktopStoreSwitcher() {
        const {
            storeList,
            onStoreSwitcherOutsideClick,
            onStoreSwitcherClick,
            isOpened,
            storeLabel
        } = this.props;

        const mods = { isOpen: isOpened };

        return (
            <div block="StoreSwitcher">
                <div block="StoreSwitcher" elem="StoreList" mods={ mods }>
                    { storeList.map(this.renderStoreList) }
                </div>        
            </div>
        );
    }
}

export default StoreSwitcher;