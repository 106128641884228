import ParcelshopPicker from '../component/ParcelshopPicker';
import CommentField from '../component/CommentField';
import { createRef, PureComponent } from 'react';
import { getFormFields, trimAddressFields } from 'Util/Address';

export class CheckoutShippingPlugin extends PureComponent {
    selectParcelshop(shop){
        this.setState({
            selectedParcelShop: shop
        });
    }

    onShippingComment(text){
        this.setState({
            shippingComment: text
        });
    }

    addContainerFunctions = (member, context) => {
        return {
            ...member,
            onParcelshopSelect: this.selectParcelshop.bind(context),
            onShippingComment: this.onShippingComment.bind(context)
        }
    }

    renderParcelshopPicker(args, callback, instance){
        const { address, onShippingComment, shippingComment, selectedCustomerAddressId, onParcelshopSelect, selectedParcelShop, selectedShippingMethod } = instance.props;
        return (
            <>
                {callback(...args)}
                <CommentField 
                    active={ selectedShippingMethod && !!selectedShippingMethod.comment } 
                    onChange={ onShippingComment }
                    value={ shippingComment } 
                />
                <ParcelshopPicker
                    address={ address || '' } 
                    active={ selectedShippingMethod && !!selectedShippingMethod.parcelshop } 
                    selectedShippingMethod={ selectedShippingMethod || '' }
                    selectedShop={selectedParcelShop} 
                    onSelect={onParcelshopSelect} />
            </>
        );
    };

    addActiveOverlayToProps(args, callback, instance){    
        const [state] = args;
        return {
            ...callback(...args),
            activeOverlay: state.OverlayReducer.activeOverlay      
        }
    };

    addShowOverlayToProps(args, callback, instance){
        const [dispatch] = args;
        return {
            ...callback(...args),
            showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey))      
        }
    };

    saveZipCode(address) {
        const { onShippingEstimationFieldsChange } = this.props;
        this.setState({address: address});
        return onShippingEstimationFieldsChange(address);
    }

    saveAddressInformationOverride = (args, callback, instance) => {
        const component = callback(...args);
        if (!instance.shippingRef){
            instance.shippingRef = createRef();
        }
        const component2 = <component.type ref={instance.shippingRef} {...component.props} saveAddressInformation={this.saveAddressInformation.bind(instance)} />;
        instance.c2 = component2;
        return component2;
    }

    saveAddressInformation(data){
        console.log("TEST", this.shippingRef);
        console.log("TEST", this.c2);
        // const { 
        //     selectedParcelShop: {
        //         label: pickupName,
        //         id: pickupId
        //     },
        //     selectedShippingMethod: {
        //         comment
        //     },
        //     shippingComment
        //  } = this.shippingRef.current.state;
         const newData = {
            ...data,
            // shipping_pickup_id: pickupId,
            // shipping_pickup_name: pickupName,
            // shipping_comment: comment ? shippingComment : null
         };
         console.log("DATA", newData);
         throw "err";
    }

    ship = (args, callback, instance) => {
        const [fields] = args;
        const {
            saveAddressInformation,
            updateShippingFields,
            addressLinesQty
        } = instance.props;

        const {
            selectedCustomerAddressId,
            selectedShippingMethod,
            selectedParcelShop,
            shippingComment
        } = instance.state;

        const formFields = getFormFields(fields, addressLinesQty);

        const shippingAddress = selectedCustomerAddressId
            ? instance._getAddressById(selectedCustomerAddressId)
            : trimAddressFields(formFields);

        const {
            carrier_code: shipping_carrier_code,
            method_code: shipping_method_code,
            comment
        } = selectedShippingMethod;

        const data = {
            billing_address: shippingAddress,
            shipping_address: shippingAddress,
            shipping_carrier_code,
            shipping_method_code,
            shipping_pickup_point: this.parsePickupData(selectedParcelShop),
            shipping_comment: comment ? shippingComment : null
        };
        console.log(data);

        saveAddressInformation(data);
        updateShippingFields(fields);
    }

    parsePickupData(data){
        if (!data) return null;
        const { name, address, zipcode, city, country, id, label } = data;
        return {
            pickup_id: id,
            pickup_name: label,
            pickup_company: name,
            pickup_street: address,
            pickup_postcode: zipcode,
            pickup_city: city,
            pickup_country: country
        };
    }

    propsOverride = (args, callback, instance) => {
        const component = callback(...args);
        return (
            <component.type
              { ...component.props }
              onShippingEstimationFieldsChange={ this.saveZipCode.bind(instance)}
            />
        );
    }
}

const { renderParcelshopPicker, addContainerFunctions, addShowOverlayToProps, addActiveOverlayToProps, saveZipCode, propsOverride, ship, saveAddressInformationOverride } = new CheckoutShippingPlugin();

export default {
    'Component/CheckoutShipping/Component': {
        'member-function': {
            renderDelivery: renderParcelshopPicker,
        }
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            render: propsOverride,
            onShippingSuccess: ship
        },
        'member-property': {
            containerFunctions: addContainerFunctions,
        }
    },
    'Component/CheckoutShipping/Container/mapDispatchToProps': {
        'function': addShowOverlayToProps
    },
    'Component/CheckoutShipping/Container/mapStateToProps': {
        'function': addActiveOverlayToProps
    },
    'Route/Checkout/Component': {
        'member-function': {
            // renderShippingStep: saveAddressInformationOverride
        }
    }
};