/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_ADD_PAYMENT_INFO } from '../../../util/Event';
import ProductHelper from '../utils/Product4';
import BaseEvent from './BaseEvent.event';

/**
 * Add payment info event
 */
class AddPaymentInfo4Event extends BaseEvent {
    /**
     * Bind add payment info
     */
    bindEvent() {
        Event.observer(EVENT_GTM_ADD_PAYMENT_INFO, ({ totals, paymentMethod }) => {
            this.handle(totals, paymentMethod);
        });
    }

    /**
     * Handle add payment info
     */
    handler(totals, paymentMethod) {
        const {
            items: cartItems = []
        } = totals;

        const items = cartItems.map(({ qty, ...cartItem}, idx) => ({
            ...ProductHelper.getItemData(cartItem),
            quantity: qty,
            index: idx
        }));

        const currency = this.getCurrencyCode();

        const value = ProductHelper.getItemsValue(items);

        this.clearEcommerce();

        this.pushEventData({
            ecommerce: {
                currency,
                value,
                payment_type: paymentMethod,
                items
            }
        });
    }
}

export default AddPaymentInfo4Event;
