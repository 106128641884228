const addParcelshop = (args, callback) => {
    return [
        ...callback(...args),
        'parcelshop',
        'comment'
    ];
};

export default {
    'Query/Checkout': {
        'member-function': {
            _getEstimatedShippingFields: addParcelshop
        }
    }
};