 import Event, {
    EVENT_GTM_VIEW_ITEM_LIST
} from '../../../util/Event';

import BaseEvent from './BaseEvent.event';
import ProductHelper, {
    LIST_ID_CATEGORY,
    LIST_NAME_MAP
} from '../utils/Product4';

export const EVENT_HANDLE_DELAY = 700;
export const SPAM_PROTECTION_DELAY = 600;

class ViewItemList4Event extends BaseEvent {
    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = EVENT_HANDLE_DELAY;

    bindEvent() {
        Event.observer(EVENT_GTM_VIEW_ITEM_LIST, ({ items , listId }) => {
            this.handle(items, listId);
        });
    }

    handler(products = [], listId = LIST_ID_CATEGORY) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY, EVENT_GTM_VIEW_ITEM_LIST)) {
            return;
        }

        const items = products.map((product, index) => ({
            ...ProductHelper.getProductData(product),
            index,
            item_list_id: listId,
            item_list_name: LIST_NAME_MAP[listId]
        }));

        this.clearEcommerce();

        this.pushEventData({
            ecommerce: {
                item_list_id: listId,
                item_list_name: LIST_NAME_MAP[listId],
                items
            }
        });
    }
}

export default ViewItemList4Event;
