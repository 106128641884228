import Event, {
    EVENT_GTM_ADD_PAYMENT_INFO
} from '../util/Event';

class AddPaymentInfoPlugin {
    persisted = {};

    savePaymentInformation = (args, callback, instance) => {
        const result = callback.apply(instance, args);

        const [paymentInformation] = args;

        const { totals } = instance.props;

        this.persisted = {
            paymentInformation: JSON.parse(JSON.stringify(paymentInformation)),
            totals: JSON.parse(JSON.stringify(totals)),
        }

        return result;
    };

    saveBillingAddressThen = (args, callback, instance) => {
        const result = callback.apply(instance, args);

        const {
            paymentInformation: {
                paymentMethod: {
                    code: paymentMethod
                } = {}
            } = {},
            totals
        } = this.persisted;

        Event.dispatch(EVENT_GTM_ADD_PAYMENT_INFO, {
            totals,
            paymentMethod
        });

        this.persisted = {};

        return result;
    };
}

const {
    savePaymentInformation,
    saveBillingAddressThen,
} = new AddPaymentInfoPlugin();

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentInformation
        }
    },
    'Route/Checkout/Container/saveBillingAddressThen': {
        'function': saveBillingAddressThen
    }
};
