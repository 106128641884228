import { debounce } from 'Util/Request';
import { UPDATE_STATE_FREQUENCY } from 'Component/CheckoutAddressForm/CheckoutAddressForm.config';

const streetOnChange = (member, context) => {
    context.onStreetChange = debounce((value) => context.setState({ street: value }), UPDATE_STATE_FREQUENCY);
    return {
        ...member,
        street: {
            ...member.street,
            onChange: (value) => context.onStreetChange(value)
        }
    }
}
const addStreetToEstimate = (args, callback, instance) => {
    const { onShippingEstimationFieldsChange } = instance.props;

    const {
        countryId,
        regionId,
        region,
        city,
        postcode,
        street
    } = instance.state;

    onShippingEstimationFieldsChange({
        country_id: countryId,
        region_id: regionId,
        region,
        city,
        postcode,
        street
    });
}

export default {
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            fieldMap: streetOnChange,
            estimateShipping: addStreetToEstimate
        }
    }
};