import Event, {
    EVENT_GTM_ADD_TO_WISHLIST
} from '../util/Event';

class AddToWishlistPlugin {
    persisted = {};

    toggleProductInWishlist = (args, callback, instance) => {
        const result = callback.apply(instance, args);

        const {
            product,
            configurableVariantIndex
        } = instance.props;

        this.persisted = {
            product: { ...product, configurableVariantIndex }
        };

        return result;
    }

    addItemToWishlistFetchMutationThen = (args, callback, instance) => {
        const result = callback.apply(instance, args);

        const {
            product
        } = this.persisted;

        Event.dispatch(EVENT_GTM_ADD_TO_WISHLIST, {
            product
        });

        this.persisted = {};

        return result;
    };
}

const {
    toggleProductInWishlist,
    addItemToWishlistFetchMutationThen,
} = new AddToWishlistPlugin();

export default {
    'Component/ProductWishlistButton/Container': {
        'member-function': {
            toggleProductInWishlist
        }
    },
    'Store/Wishlist/Dispatcher/addItemToWishlistFetchMutationThen': {
        'function': addItemToWishlistFetchMutationThen
    }
};