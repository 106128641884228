/* eslint-disable */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Overlay from 'Component/Overlay';
import Menu from 'SourceComponent/Menu/Menu.component';
import './MenuOverlay.base.override.style.scss';

/** @namespace Component/MenuOverlay/Component */
export class MenuOverlayComponent extends Menu {
    static propTypes = {
        isHideOverlay: PropTypes.bool,
        isActive: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isHideOverlay: false
    };

    renderTopLevel() {
        return (
            <>
                { this.renderNav() }
                { super.renderTopLevel() }
            </>
        )
        
    }

    renderNav(){
        const { activeMenuItemsStack, menu, goBack } = this.props;
        const [mainMenu] = Object.values(menu);
        const isVisible = !!activeMenuItemsStack.length;
        const currentItem = activeMenuItemsStack.slice().reduceRight((acc, itemId) => {
            const {
                children: {
                    [itemId]: currentItem
                }
            } = acc;

            return currentItem;
        }, mainMenu);
        const { title } = currentItem;

        return (
            <nav block="Header" elem="Nav">
                <button 
                    block="Header" 
                    elem="Button" 
                    mods={ { type: 'back', isVisible } }
                    onClick={goBack}
                    aria-label="Go back"
                    aria-hidden={ !isVisible }
                    tabIndex={ isVisible ? 0 : -1 }></button>
                <h1 block="Header" elem="Title" mods={ { isVisible } }>{ title }</h1>
            </nav>
        );
    }

    render() {
        const { isActive } = this.props;

        if (!isActive) {
            return null;
        }

        return (
            <Overlay
              id="menu"
              mix={ { block: 'MenuOverlay' } }
            >
                <article
                  block="MenuOverlay"
                  elem="Results"
                  aria-label="Search results"
                >
                    { super.render() }
                </article>
            </Overlay>
        );
    }
}

export default MenuOverlayComponent;
