import Event, {
    EVENT_GTM_ADD_SHIPPING_INFO
} from '../util/Event';

class AddShippingInfoPlugin {
    persisted = {};

    saveAddressInformation = (args, callback, instance) => {
        const result = callback.apply(instance, args);

        const [addressInformation] = args;

        const { totals } = instance.props;

        this.persisted = {
            addressInformation: JSON.parse(JSON.stringify(addressInformation)),
            totals: JSON.parse(JSON.stringify(totals)),
        }

        return result;
    };

    saveAddressInformationFetchMutationThen = (args, callback, instance) => {
        const result = callback.apply(instance, args);

        const {
            addressInformation: {
                shipping_carrier_code: carrierCode,
                shipping_method_code: methodCode,
            },
            totals
        } = this.persisted;

        if (carrierCode && methodCode && totals) {
            Event.dispatch(EVENT_GTM_ADD_SHIPPING_INFO, {
                totals,
                shippingMethod: `${carrierCode}_${methodCode}`
            });

            this.persisted = {};
        }

        return result;
    };
}

const {
    saveAddressInformation,
    saveAddressInformationFetchMutationThen
} = new AddShippingInfoPlugin();

export default {
    'Route/Checkout/Container': {
        'member-function': {
            saveAddressInformation
        }
    },
    'Route/Checkout/Container/saveAddressInformationFetchMutationThen': {
        'function': saveAddressInformationFetchMutationThen
    }
};
