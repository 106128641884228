import {
    LIST_ID_CATEGORY,
    LIST_ID_SEARCH,
    LIST_ID_WISHLIST,
    LIST_ID_PRODUCT,
    LIST_ID_HOME,
    LIST_ID_CMS
} from '../component/GoogleTagManager/utils';

const addListId = (listId, priority = 0) => (args, callback, instance) => {
    const gtmListId = (window.gtmListId || []);
    gtmListId.push({
        listId,
        priority
    });
    window.gtmListId = gtmListId;
    return callback.apply(instance, args);
};

const removeListId = (listId) => (args, callback, instance) => {
    window.gtmListId = (window.gtmListId || []).filter(({ listId: oldListId }) => oldListId !== listId);
    return callback.apply(instance, args);
};

export default {
    'Route/HomePage/Container': {
        'member-function': {
            componentDidMount: addListId(LIST_ID_HOME),
            componentWillUnmount: removeListId(LIST_ID_HOME)
        }
    },
    'Route/CmsPage/Container': {
        'member-function': {
            componentDidMount: addListId(LIST_ID_CMS, -10),
            componentWillUnmount: removeListId(LIST_ID_CMS)
        }
    },
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidMount: addListId(LIST_ID_PRODUCT),
            componentWillUnmount: removeListId(LIST_ID_PRODUCT)
        }
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            componentDidMount: addListId(LIST_ID_CATEGORY),
            componentWillUnmount: removeListId(LIST_ID_CATEGORY)
        }
    },
    'Route/SearchPage/Container': {
        'member-function': {
            componentDidMount: addListId(LIST_ID_SEARCH, 10),
            componentWillUnmount: removeListId(LIST_ID_SEARCH)
        }
    },
    'Component/MyAccountMyWishlist/Container': {
        'member-function': {
            componentDidMount: addListId(LIST_ID_WISHLIST, -10),
            componentWillUnmount: removeListId(LIST_ID_WISHLIST)
        }
    }
};