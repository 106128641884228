/* eslint-disable */

import { Image as HoeksImage } from 'HoeksComponent/Image/Image.component';

import {
    IMAGE_LOADED, IMAGE_LOADING, IMAGE_NOT_FOUND, IMAGE_NOT_SPECIFIED
} from './Image.config';

export class Image extends HoeksImage { 
    renderImage() {
        const {
            alt,
            isPlaceholder,
            src,
            style,
            title,
            mix,
        } = this.props;
        const { imageStatus } = this.state;

        let loadingType = 'lazy';
        let imageElem = mix?.elem;
        let imageBlock = mix?.block;
        
        if( imageElem === 'SliderImage' && imageBlock === 'ProductGallery' ) {
            loadingType = 'eager';
        }

        if (isPlaceholder) {
            return null;
        }

        switch (imageStatus) {
        case IMAGE_NOT_FOUND:
            return this.renderImageNotFound();
        case IMAGE_NOT_SPECIFIED:
            return (
                <span block="Image" elem="Content">{ __('Image not specified') }</span>
            );
        case IMAGE_LOADED:
        case IMAGE_LOADING:
            return (
                <img
                  block="Image"
                  elem="Image"
                  src={ src || '' }
                  alt={ alt }
                  mods={ { isLoading: imageStatus === IMAGE_LOADING } }
                  style={ style }
                  title={ title }
                  onLoad={ this.onLoad }
                  onError={ this.onError }
                  loading={ loadingType }
                />
            );
        default:
            return null;
        }
    }
}

export default Image;