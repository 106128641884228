import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import GoogleTagManager from '../component/GoogleTagManager/GoogleTagManager.component';
import GtmQuery from '../query/Gtm.query';

const handle_syncCartWithBEError = (args, callback, instance) => {
    return callback(...args)
        .then(
            (result) => {
                GoogleTagManager.getInstance().setGroupedProducts({});
                return result;
            }
        );
};

const addGtmConfigQuery = (args, callback, instance) => {
    const original = callback(...args);
    return [
        ...(Array.isArray(original) ? original : [original]),
        GtmQuery.getGTMConfiguration()
    ];
};

const addGtmToConfigReducerInitialState = (args, callback, instance) => {
    const { gtm } = BrowserDatabase.getItem('config') || { gtm: {} };

    return {
        ...callback(...args),
        gtm
    };
};

const addGtmToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { gtm } = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        gtm
    };
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            'handle_syncCartWithBEError': handle_syncCartWithBEError
        }
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            'prepareRequest': addGtmConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        'function': addGtmToConfigReducerInitialState
    },
    'Store/Config/Reducer': {
        'function': addGtmToConfigUpdate
    }
};
