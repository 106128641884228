/* eslint-disable */

// // imports the original component from vendor folder
// import Header from 'SourceComponent/Header/Header.component';

// // import new extended styles file
// import './Header.override.style.scss';

// export default Header;

import { Header as HoeksHeader } from 'HoeksComponent/Header/Header.component';
import NavigationTabs from 'HoeksComponent/NavigationTabs';
import CurrencySwitcher from 'Component/CurrencySwitcher';
import StoreSwitcher from 'Component/StoreSwitcher';
import CmsBlock from 'Component/CmsBlock';
// import new extended style file
import './Header.override.style.scss';

export class Header extends HoeksHeader { 

    renderContacts() {
        return (
            <></>
        );
    }

    renderUspBlock() {
        return (
            <CmsBlock identifier={ "header-usp" } />
        );
    }

    renderLogoTopMenu() {
        const { device } = this.props;
        if (device.isMobile) {
            return null;
        }
        return (
            <>
                <div block="Header" elem="TopMenu">
                    <div block="Header" elem="TopMenuWrapper">
                        <div block="Header" elem="Usp">
                            { this.renderUspBlock() }
                        </div>
                        <div block="Header" elem="Switcher">
                            <CurrencySwitcher />
                            <StoreSwitcher />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderTopMenu() {
        return (
            <>
                 { this.renderLogoTopMenu() }
                <NavigationTabs />
            </>
        );
    }

}

export default Header;