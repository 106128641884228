/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, {
    EVENT_GTM_ADD_TO_CART
} from '../util/Event';
import { getStore } from 'Util/Store';

import { GROUPED } from 'Util/Product/Types';

const addProductToCart = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    result.then(() => {
        const [_, { product, quantity }] = args;
        const { type_id } = product;

        if (type_id === GROUPED) {
            // TODO: handle grouped products
        } else {
            const {
                configurableVariantIndex = -1
            } = product;

            Event.dispatch(EVENT_GTM_ADD_TO_CART, {
                product,
                quantity,
                configurableVariantIndex
            });
        }
    });

    return result;
};

const changeItemQty = (args, callback, instance) => {
    const {
        cartTotals: {
            items: prevItems = []
        } = {}
    } = getStore().getState().CartReducer;

    const result = callback.apply(instance, args);

    result.then((data) => {
        const [_, { item_id: itemId, quantity }] = args;
        const { items } = data;

        const prevItem = prevItems.find(({ item_id: prevItemId }) => prevItemId === itemId);
        const item = items.find(({ item_id }) => item_id === itemId);

        if (prevItem && item) {
            const {
                qty: prevQuantity
            } = prevItem;

            const {
                product: {
                    type_id
                } = {}
            } = item;

            if (type_id === GROUPED) {
                // TODO: handle grouped products
            } else {
                if (prevQuantity < quantity) {
                    const addedQuantity = quantity - prevQuantity;

                    Event.dispatch(EVENT_GTM_ADD_TO_CART, {
                        product: item,
                        quantity: addedQuantity,
                        isItem: true
                    });
                }
            }
        }

        return data;
    });

    return result;
};

const _updateCartData = (args, callback, instance) => {
    const [cartData] = args;
    callback.apply(instance, args);
    return cartData;
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            addProductToCart,
            _updateCartData,
            changeItemQty
        }
    }
};
