/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Event names
 *
 * @type {string}
 */
export const EVENT_GTM_ADD_PAYMENT_INFO = 'gtm_add_payment_info';
export const EVENT_GTM_ADD_SHIPPING_INFO = 'gtm_add_shipping_info';
export const EVENT_GTM_ADD_TO_CART = 'gtm_add_to_cart';
export const EVENT_GTM_ADD_TO_WISHLIST = 'gtm_add_to_wishlist';
export const EVENT_GTM_BEGIN_CHECKOUT = 'gtm_begin_checkout';
export const EVENT_GTM_GENERAL = 'gtm_general';
export const EVENT_GTM_PURCHASE = 'gtm_purchase';
export const EVENT_GTM_REMOVE_FROM_CART = 'gtm_remove_from_cart';
export const EVENT_GTM_SELECT_ITEM = 'gtm_select_item';
export const EVENT_GTM_VIEW_CART = 'gtm_view_cart';
export const EVENT_GTM_VIEW_ITEM = 'gtm_view_item';
export const EVENT_GTM_VIEW_ITEM_LIST = 'gtm_view_item_list';
