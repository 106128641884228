/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

export class GtmQuery {
    _getGTMConfigurationFields = () => ([
        'enabled',
        'gtm_id',
        'gtm_script',
        this.getEventsField()
    ]);

    getEventsField = () => new Field('events').addFieldList(this.getEvents());

    getEvents = () => ([
        'gtm_add_payment_info',
        'gtm_add_shipping_info',
        'gtm_add_to_cart',
        'gtm_add_to_wishlist',
        'gtm_begin_checkout',
        'gtm_purchase',
        'gtm_remove_from_cart',
        'gtm_select_item',
        'gtm_view_cart',
        'gtm_view_item',
        'gtm_view_item_list',
    ]);

    getGTMConfiguration = () => {
        return new Field('getGtm')
            .setAlias('gtm')
            .addFieldList(this._getGTMConfigurationFields());
    };
}

export default new GtmQuery();
