import './CommentField.override.style.scss';
import Field from 'Component/Field';

export class CommentField extends PureComponent {
    renderField(){
        const { onChange, value } = this.props;
        return (
            <Field
                type="text"
                label={ __('If you wish the shipment to be delivered without a receipt, this happens on the customers own responsibility, please write below were the carrier may leave the packet.') }
                id="shipping_comment"
                name="shipping_comment"
                validationStatus={['notEmpty']}
                onChange={ onChange }
                value={value}
              />
        );
    }

    render(){
        const { active, isLoading } = this.props;
        if (!active){
            return null;
        }

        return (
            <div block="CommentField">
                {this.renderField()}
            </div>
        );
    }
}

export default CommentField;