/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_VIEW_CART } from '../../../util/Event';
import ProductHelper from '../utils/Product4';
import BaseEvent from './BaseEvent.event';

export const SPAM_PROTECTION_DELAY = 1000;

/**
 * View cart event
 */
class ViewCart4Event extends BaseEvent {
    /**
     * Bind view cart
     */
    bindEvent() {
        Event.observer(EVENT_GTM_VIEW_CART, ({ totals }) => {
            this.handle(totals);
        });
    }

    /**
     * Handle view cart
     */
    handler(totals) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY, EVENT_GTM_VIEW_CART)) {
            return;
        }

        const {
            quote_currency_code,
            grand_total,
            items: cartItems = []
        } = totals;

        const items = cartItems.map(({ qty, ...cartItem }) => ({
            ...ProductHelper.getItemData(cartItem),
            quantity: qty
        }));

        this.clearEcommerce();

        this.pushEventData({
            ecommerce: {
                currency: quote_currency_code,
                value: grand_total,
                items
            }
        });
    }
}

export default ViewCart4Event;
