/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BrowserDatabase from 'Util/BrowserDatabase';

/**
 * Check if push event is enabled in config
 * @param eventName
 * @returns {boolean}
 */
export const isEventEnabled = (eventName) => {
    const {
        gtm: {
            events = {}
        } = {}
    } = BrowserDatabase.getItem('config') || {};

    return !!events[mapGtmEventNames(eventName)];
};

/**
 * Map GTM event names to config event names
 * @param name
 * @returns {string}
 */
export const mapGtmEventNames = (name) => {
    return name;
};

/**
 * Get current item_list_id
 * @returns {string}
 */
export const getListId = () => (window.gtmListId || []).reduce(
    ({ listId: accListId, priority: accPriority }, { listId, priority }) => {
        if (accPriority > priority) {
            return {
                listId: accListId,
                priority: accPriority
            };
        } else {
            return {
                listId,
                priority
            };
        }
    },
    { listId: 'unknown', priority: -1000 }
).listId;