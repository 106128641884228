/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_SELECT_ITEM } from '../../../util/Event';
import ProductHelper, { LIST_NAME_MAP } from '../utils/Product4';
import BaseEvent from './BaseEvent.event';

export const EVENT_HANDLE_DELAY = 0;

/**
 * Select item event
 */
class SelectItem4Event extends BaseEvent {
    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = EVENT_HANDLE_DELAY;

    /**
     * Bind select item
     */
    bindEvent() {
        Event.observer(EVENT_GTM_SELECT_ITEM, ({ product, listId }) => {
            this.handle(product, listId);
        });
    }

    /**
     * Handle select item
     */
    handler(product, listId) {
        const items = [
            {
                ...ProductHelper.getProductData(product),
                index: 0,
                item_list_id: listId,
                item_list_name: LIST_NAME_MAP[listId],
            }
        ];

        this.clearEcommerce();

        this.pushEventData({
            ecommerce: {
                item_list_id: listId,
                item_list_name: LIST_NAME_MAP[listId],
                items
            }
        });
    }
}

export default SelectItem4Event;
