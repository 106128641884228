import Event, {
    EVENT_GTM_BEGIN_CHECKOUT
} from '../util/Event';

import { CHECKOUT_EVENT_DELAY } from '../component/GoogleTagManager/events/BeginCheckout4.event';

const componentDidMount = (args, callback, instance) => {
    const { totals = {} } = instance.props;

    setTimeout(
        () => Event.dispatch(EVENT_GTM_BEGIN_CHECKOUT, {
            totals
        }),
        CHECKOUT_EVENT_DELAY
    );

    return callback(...args);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            componentDidMount
        }
    }
};
