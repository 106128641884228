import Event, {
    EVENT_GTM_VIEW_ITEM
} from '../../../util/Event';

import BaseEvent from './BaseEvent.event';
import ProductHelper, { LIST_NAME_MAP, LIST_ID_PRODUCT } from '../utils/Product4';

export const EVENT_HANDLE_DELAY = 700;

class ViewItem4Event extends BaseEvent {
    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = EVENT_HANDLE_DELAY;

    bindEvent() {
        Event.observer(EVENT_GTM_VIEW_ITEM, ({ product }) => {
            this.handle(LIST_ID_PRODUCT, product);
        });
    }

    handler(listType = LIST_ID_PRODUCT, product = {}) {
        const items = [
            {
                ...ProductHelper.getProductData(product),
                index: 0,
                item_list_id: listType,
                item_list_name: LIST_NAME_MAP[listType],
            }
        ];

        const currency = this.getCurrencyCode();

        const value = ProductHelper.getItemsValue(items);

        this.clearEcommerce();

        this.pushEventData({
            ecommerce: {
                currency,
                value,
                items
            }
        });
    }
}

export default ViewItem4Event;
