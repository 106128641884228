import ParcelshopPicker from './ParcelshopPicker.component';
import ParcelshopsQuery from '../../query/Parcelshops.query';
import { fetchQuery } from 'Util/Request';
import { debounce } from 'Util/Request';

export class ParcelshopPickerContainer extends PureComponent {

    static defaultProps = {
        prevSelectedShippingMethod: {}
    };

    __construct(props) {
        super.__construct(props);
    }

    state = {
        isLoading: false,
        shops: [],
        options: []
    }

    componentDidMount() {
        this.doUpdate = debounce(() => this.update(), 500);
    }    

    componentDidUpdate(prevProps, prevState) {
        const { selectedShippingMethod: { method_code = {}}, address: { postcode: addresspostcode, country, address, city } } = this.props;
        const { selectedShippingMethod: { method_code: prev_method_code = {}}, address: { postcode: prevAddresspostcode} } = prevProps;

        const { postcode = addresspostcode } = this.state;
        const { postcode: prevPostcode = prevAddresspostcode} = prevState;

        if (method_code && postcode && (method_code != prev_method_code || postcode != prevPostcode)){
            this.doUpdate();
        }
    }

    containerFunctions = {
        onPostChange: this.onPostcodeChange.bind(this)
    };

    onPostcodeChange(code) {
        if (code !== "") {
            this.setState({postcode: code});
        }
    }

    update = async () => {
        const { selectedShippingMethod, onSelect, address: { postcode: addresspostcode, country_id, street, city } } = this.props;
        const { postcode = addresspostcode } = this.state;
        if (selectedShippingMethod) {
            const { method_code, carrier_code } = selectedShippingMethod;
            this.setState({isLoading: true });
            onSelect(null);
            try {
                const args = postcode === addresspostcode ?          
                    { method: method_code, carrier: carrier_code, postcode: postcode, country_code: country_id, address: street, city: city } : 
                    { method: method_code, carrier: carrier_code, postcode: postcode }
                const { shops: { shops} } = await fetchQuery([ParcelshopsQuery.getParcelshopsQuery(args)]);
                const options = shops.map(shop => ({id: shop.id, value: shop.id, label: this.renderLabel(shop), disabled: false}));
                this.setState({isLoading: false, shops: shops, options: options });
                if (options.length){
                    const shop = shops[0]; 
                    shop.label = options[0].label;
                    onSelect(shop);
                }

            }
            catch (error){
                this.setState({isLoading: false, shops: [], options: [] });
            }
        }
    }

    renderLabel(shop){
        const distance = shop.distance ? ''+(shop.distance/1000).toFixed(1)+' km - ' : '';
        return distance + [shop.name, shop.address, shop.zipcode+' '+shop.city].join(', ');
    }

    render(){
        const { address: { postcode: addresspostcode } } = this.props;
        const { postcode = addresspostcode } = this.state;

        return <ParcelshopPicker {...this.props} {...this.state} {...this.containerFunctions} postcode={postcode}/>
    }
}

export default ParcelshopPickerContainer;