/* eslint-disable */

// imports the original component from vendor folder
import HoeksMenu from 'HoeksComponent/Menu/Menu.component';
import CmsBlock from 'Component/CmsBlock';
import Link from 'Component/Link';
// import new extended styles file
import './Menu.override.style.scss';

export class Menu extends HoeksMenu { 


    renderPromotionCms() {
        const { closeMenu } = this.props;
        const { header_content: { header_cms } = {} } = window.contentConfiguration;

        if (header_cms) {
            return <CmsBlock identifier={ header_cms } />;
        }
    }
}

export default Menu;