/* eslint-disable */

import CmsBlock from 'Component/CmsBlock';
// imports the original component from vendor folder
import { Footer as SourceFooter } from 'SourceComponent/Footer/Footer.component';

import { NEWSLETTER_COLUMN } from './Footer.config';

// import new extended styles file
import './Footer.override.style.scss';

export class Footer extends SourceFooter {
    renderCopyrightContent() {
        return (
            <CmsBlock identifier={ "footer-copyright" } />
        );
    }


    render() {
        const { isVisibleOnMobile, device } = this.props;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }

        return (
            <footer block="Footer" aria-label="Footer">

                { this.renderCopyrightContent() }
            </footer>
        );
    }

}

export default Footer;
