/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_ADD_TO_WISHLIST } from '../../../util/Event';
import ProductHelper from '../utils/Product4';
import BaseEvent from './BaseEvent.event';
import { LIST_NAME_MAP, LIST_ID_WISHLIST } from '../utils/Product4';

/**
 * Product add to wishlist event
 */
class AddToWishlist4Event extends BaseEvent {
    /**
     * Bind add to wishlist
     */
    bindEvent() {
        Event.observer(EVENT_GTM_ADD_TO_WISHLIST, ({ product }) => {
            this.handle(product);
        });
    }

    /**
     * Handle add to wishlist
     */
    handler(product) {
        const items = [
            {
                ...ProductHelper.getProductData(product),
                index: 0,
                item_list_id: LIST_ID_WISHLIST,
                item_list_name: LIST_NAME_MAP[LIST_ID_WISHLIST],
            }
        ];

        const currency = this.getCurrencyCode();

        const value = ProductHelper.getItemsValue(items);

        this.clearEcommerce();

        this.pushEventData({
            ecommerce: {
                currency,
                value,
                items
            }
        });
    }
}

export default AddToWishlist4Event;
