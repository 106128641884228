/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_REMOVE_FROM_CART } from '../../../util/Event';
import ProductHelper from '../utils/Product4';
import BaseEvent from './BaseEvent.event';

export const SPAM_PROTECTION_DELAY = 200;

/**
 * Product remove from cart event
 */
class RemoveFromCart4Event extends BaseEvent {
    /**
     * Bind remove from cart
     */
    bindEvent() {
        Event.observer(EVENT_GTM_REMOVE_FROM_CART, ({
            product,
            quantity,
            configurableVariantIndex,
            isItem = false
        }) => {
            this.handle(
                { configurableVariantIndex, ...product },
                quantity || 1,
                isItem
            );
        });
    }

    /**
     * Handle remove from cart
     */
    handler(product, quantity, isItem) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const items = [
            {
                ...(isItem ? ProductHelper.getItemData(product) : ProductHelper.getProductData(product)),
                quantity,
                index: 0
            }
        ];

        const currency = this.getCurrencyCode();

        const value = ProductHelper.getItemsValue(items);

        this.clearEcommerce();

        this.pushEventData({
            ecommerce: {
                currency,
                value,
                items
            }
        });
    }
}

export default RemoveFromCart4Event;
