/* eslint-disable */
import { StoreItems as SourceStoreItems } from 'HoeksComponent/StoreItems/StoreItems.component';

// import new extended style file
import './StoreItems.override.style.scss';

export class StoreItems extends SourceStoreItems {

    render() {
        const {
            item: { label },
            getStoreCode
        } = this.props;

        return (
            <button
              block="StoreItems"
              elem="Item"
              mods={ { label } }
              onClick={ getStoreCode }
            >
                
            </button>
        );
    }
}

export default StoreItems;
