import KlarnaQuery from 'Query/Klarna.query';
import {
    KLARNA_PAYMENTS_CONTAINER_ID
} from 'SourceComponent/Klarna/Klarna.config';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';

const initiateKlarna = async (args, callback, instance) => {
    const { showError, setOrderButtonEnableStatus } = instance.props;
    const guest_cart_id = getGuestQuoteId();

    try {
        setOrderButtonEnableStatus(false);

        const { klarnaToken: client_token } = await fetchMutation(
            KlarnaQuery.getCreateKlarnaTokenMutation(
                !isSignedIn() ? { guest_cart_id } : {}
            )
        );

        window.Klarna.Payments.init({ client_token });
        window.Klarna.Payments.load({
            container: `#${KLARNA_PAYMENTS_CONTAINER_ID}`,
            payment_method_category: localStorage.getItem('kl_pm')
        });

        setOrderButtonEnableStatus(true);
    } catch (err) {
        console.groupCollapsed('Suppressed error log:');
        console.error(err);
        console.groupEnd();

        showError(__('Error initializing Klarna payment method.'));
    }

    instance.setState({ isLoading: false });
};

export default {
    'Component/Klarna/Component': {
        'member-function': {
            initiateKlarna
        }
    }
};
